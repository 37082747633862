import React, { Component } from "react";
import { Field } from "../../../../components/index";
import { Form, Button, Col, Row, Table as TableBoot } from "react-bootstrap";
// import swal from "@sweetalert/with-react";
import dayjs from "dayjs";
import domtoimage from "dom-to-image";
import JsPDF from "jspdf";
import "../admin/css.css";
import "dayjs/locale/th";
import { POST } from "api";

const tofieds = (num) => Number(Number(num || 0).toFixed(0)).toLocaleString();
const add = (key) => {
     return (a, b) => {
          let d = Number(b[key]) ? Number(b[key]) : b[key] ? Number(b[key].replace(/,/g, "")) : 0;
          return a + d;
     };
};
const head_stype = { textAlign: "center", verticalAlign: "middle", fontSize: "14px" };
const head_stype1 = { textAlign: "left", verticalAlign: "middle", fontSize: "14px" };
const head_1_stype = { textAlign: "center", verticalAlign: "middle", fontSize: "14px" };
const texts = { textAlign: "left", verticalAlign: "middle" };
const texts2 = { textAlign: "left", verticalAlign: "middle", fontFamily: "system-ui", fontSize: "17px", fontWeight: "bold" };
const number = { textAlign: "right", verticalAlign: "middle", fontFamily: "system-ui", fontSize: "20px" };

export default class CommissionTeam extends Component {
     constructor(...props) {
          super(...props);
          // let params = this.props.params;
          // let date = localStorage.getItem("commission_month") ? dayjs(JSON.parse(localStorage.getItem("commission_month"))).format("MM-YYYY") : null;
          // let lastData = localStorage.getItem("commission_team") ? JSON.parse(localStorage.getItem("commission_team")) : null;
          // // console.log("date", date && date === dayjs(params.date_end).format("MM-YYYY"), lastData);
          this.state = {
               ...this.props.state,
               lastData: this.props.state.lastData,
               // lastData:
               //      date && date === dayjs(params.date_end).format("MM-YYYY")
               //           ? this.props.state.lastData.map((element) => {
               //                  let data_find = lastData.filter((e) => e.saler_id === element.saler_id);
               //                  let arr_data = data_find.length
               //                       ? element.arr_data.map((el) => {
               //                              let arr_data_find = data_find.length
               //                                   ? data_find[0].arr_data.filter((e) => e.order_code_id === el.order_code_id)
               //                                   : [];
               //                              return {
               //                                   ...el,
               //                                   extar_pass: arr_data_find.length ? arr_data_find[0].extar_pass : el.extar_pass || 0,
               //                                   package: arr_data_find.length ? arr_data_find[0].package : el.package || 0,
               //                                   camban: arr_data_find.length ? arr_data_find[0].camban : el.camban || 0,
               //                                   col_t: arr_data_find.length ? arr_data_find[0].col_t : el.col_t || 0,
               //                                   col_u: arr_data_find.length ? arr_data_find[0].col_u : el.col_u || 0,
               //                                   col_v: arr_data_find.length ? arr_data_find[0].col_v : el.col_v || 0,
               //                                   extar: arr_data_find.length ? arr_data_find[0].extar : el.extar || 0,
               //                                   com_x: arr_data_find.length ? arr_data_find[0].com_x : el.com_x || 0,
               //                                   acc_x: arr_data_find.length ? arr_data_find[0].acc_x : el.acc_x || 0,
               //                                   com: arr_data_find.length ? arr_data_find[0].com : el.com || 0,
               //                                   move_car: arr_data_find.length ? arr_data_find[0].move_car : el.move_car || 0,
               //                                   sum_excel: arr_data_find.length ? arr_data_find[0].sum_excel : el.sum_excel || 0,
               //                              };
               //                         })
               //                       : element.arr_data;
               //                  //   console.log("data_find", data_find);
               //                  let cost_other = data_find.length ? data_find[0].cost_other || 0 : element.cost_other || 0;
               //                  let cost_target = data_find.length ? data_find[0].cost_target || 0 : element.cost_target || 0;
               //                  let maintenance = data_find.length ? data_find[0].maintenance || 0 : element.maintenance || 0;
               //                  return { ...element, arr_data, cost_other, cost_target, maintenance };
               //             })
               //           : this.props.state.lastData,
               edit: {},
          };
     }
     componentDidMount = () => {
          this.props.onRef(this);
     };
     saveData = async (i) => {
          let props = this.props.params;
          let state = this.state;
          // console.log("state.lastData ", state.lastData);
          // localStorage.setItem("commission_month", JSON.stringify(props.date_end));
          // localStorage.setItem("commission_team", JSON.stringify(state.lastData));
          try {
               await POST("/aos/save_commission", { date_end: props.date_end, lastData: state.lastData[i] });
          } catch (error) {}
     };
     handleChangeEditCol = (e, i, ind, name) => {
          let { lastData } = this.state;
          if (ind === "not") {
               lastData[i][name] = e.floatValue;
          } else {
               lastData[i].arr_data[ind][name] = e.floatValue;
          }
          this.setState({ lastData });
     };
     handleExportAll = () => {
          let { lastData } = this.state;
          for (let index = 0; index < lastData.length; index++) {
               const chart = document.querySelector(`#${rootContainerId}-${index + 1}`);
               exportToPdf(chart);
          }
     };
     handleExport = (index) => {
          const chart = document.querySelector(`#${rootContainerId}-${index + 1}`);
          exportToPdf(chart);
     };
     returnState = () => {
          return this.state;
     };
     render() {
          let props = this.props.params;
          let state = this.state;
          // console.log("state", state);
          let headData =
               state.lastData.length > 0
                    ? state.lastData.map((element) => {
                           let arr_data = element.arr_data.map((el) => ({
                                ...el,
                                extar_pass: el.extar_pass || 0,
                                margin_use:
                                     (el.sub_down || 0) +
                                     (el.subsidy || 0) +
                                     (el.recommend_price || 0) +
                                     (el.discount_price || 0) +
                                     (el.acc_price || 0) +
                                     (el.insurance || 0) +
                                     (el.register || 0) +
                                     (el.other_pay_price || 0),
                                package: el.package || 0,
                                camban: el.camban || 0,
                                col_t: el.col_t ? Number(el.col_t) : Number(el.col_t) === 0 ? 0 : set_col_t(el),
                                col_u: el.col_u
                                     ? Number(el.col_u)
                                     : Number(el.col_u) === 0
                                     ? 0
                                     : set_col_uv(el, element.get_car_price[0].percen_company),
                                col_v: el.col_v
                                     ? Number(el.col_v)
                                     : Number(el.col_v) === 0
                                     ? 0
                                     : set_col_uv(el, element.get_car_price[0].percen_sale),
                                col_w: el.col_v
                                     ? Number(el.col_v)
                                     : Number(el.col_v) === 0
                                     ? 0
                                     : set_col_uv(el, element.get_car_price[0].percen_sale),
                                extar: el.extar || 0,
                                com_x: el.com_x || 0,
                                acc_x: el.acc_x || 0,
                                com: el.com || 0,
                                move_car: el.move_car || 0,
                                sum_excel: el.sum_excel || 0,
                                total: set_total(
                                     el.col_v ? Number(el.col_v) : Number(el.col_v) === 0 ? 0 : set_col_uv(el, element.get_car_price[0].percen_sale),
                                     el,
                                     element
                                ),
                           }));
                           let sum_money =
                                Number(element.sum_car_price * arr_data.length) +
                                arr_data.reduce(add("extar"), 0) +
                                arr_data.reduce(add("com_x"), 0) +
                                arr_data.reduce(add("col_w"), 0) -
                                arr_data.reduce(add("acc_x"), 0) -
                                arr_data.reduce(add("com"), 0) -
                                Number(element.maintenance || 0) -
                                arr_data.reduce(add("move_car"), 0) +
                                arr_data.reduce(add("sum_excel"), 0);
                           return {
                                ...element,
                                order_old: state.setDataOld.filter((el) => el.saler_id === element.saler_id).length,
                                //===========================================================================//
                                arr_data_length: arr_data.length,
                                //===========================================================================//
                                sum_sum_car_price: element.sum_car_price * arr_data.length,
                                sum_extar: arr_data.reduce(add("extar"), 0),
                                sum_com_x: arr_data.reduce(add("com_x"), 0),
                                //===========================================================================//
                                sum_col_w: arr_data.reduce(add("col_w"), 0),
                                //===========================================================================//
                                sum_acc_x: arr_data.reduce(add("acc_x"), 0),
                                sum_com: arr_data.reduce(add("com"), 0),
                                maintenance: element.maintenance || 0,
                                sum_move_car: arr_data.reduce(add("move_car"), 0),
                                //===========================================================================//
                                sum_sum_excel: arr_data.reduce(add("sum_excel"), 0),
                                //===========================================================================//
                                sum_money,
                                //===========================================================================//
                                cost_other: element.cost_other || 0,
                                cost_target: element.cost_target || 0,
                                //===========================================================================//
                                total: sum_money + Number(element.cost_other || 0) + Number(element.cost_target || 0),
                           };
                      })
                    : [];
          return (
               <Row>
                    <Col xs={12}>
                         {/* <Button style={{ background: state.edit ? "green" : "" }} onClick={() => this.setState({ edit: !state.edit })}>
                              {state.edit ? "เสร็จสิ้น" : "แก้ไข"}
                         </Button> */}
                         {/* {!state.edit && (
                              <Button style={{ marginTop: 5, background: "#c70606", borderColor: "#c70606" }} onClick={this.handleExportAll}>
                                   พิมพ์ PDF
                              </Button>
                         )} */}
                    </Col>
                    <Col xs={12}>
                         <Button style={{ marginTop: 5, background: "#c70606", borderColor: "#c70606" }} onClick={() => this.handleExport(-1)}>
                              พิมพ์ PDF ใบปะหน้า
                         </Button>
                         <TableBoot id={`${rootContainerId}-${0}`} style={{ background: "white" }} bordered hover responsive>
                              <thead>
                                   <tr>
                                        <th colSpan={32} className="text-center" style={{ fontSize: "1.5rem" }}>
                                             <Row>
                                                  <Col xs={12}>
                                                       สรุปคอมมิชชั่นประจำเดือน{" "}
                                                       {`${dayjs(props.date_start).locale("th").format("D MMMM")} - ${dayjs(props.date_end)
                                                            .locale("th")
                                                            .format("D MMMM")} ${dayjs(props.date_end).year() + 543}`}
                                                  </Col>
                                             </Row>
                                             <Row>
                                                  <Col xs={12}>สาขา {props.branch_name}</Col>
                                             </Row>
                                             <Row>
                                                  <Col xs={12}>ทีมขาย {props.team_name}</Col>
                                             </Row>
                                        </th>
                                   </tr>
                              </thead>
                              <thead>
                                   <tr style={{ whiteSpace: "nowrap" }}>
                                        <th style={head_stype} colSpan={2}>
                                             รายละเอียด
                                        </th>
                                        <th style={head_stype} colSpan={2}>
                                             ส่งมอบ(คัน)
                                        </th>
                                        <th style={head_stype} colSpan={3}>
                                             คอมมิชชั่น
                                        </th>
                                        <th style={head_stype} rowSpan={2}>
                                             MAGIN ขายเหลือสุทธิ
                                        </th>
                                        <th style={head_stype} colSpan={4}>
                                             รายการหัก
                                        </th>
                                        <th style={head_stype} rowSpan={2}>
                                             บวกรับจาก excel
                                        </th>
                                        <th style={head_stype} colSpan={4}>
                                             รายการรับ
                                        </th>
                                   </tr>
                                   <tr style={{ whiteSpace: "nowrap" }}>
                                        <th style={head_1_stype}>ลำดับ</th>
                                        <th style={{ ...head_1_stype, minWidth: 125 }}>พนักงานขาย</th>
                                        {/*==================================================*/}
                                        <th style={head_1_stype}>เดือนที่ผ่านมา</th>
                                        <th style={head_1_stype}>เดือนปัจุบัน</th>
                                        {/*==================================================*/}
                                        <th style={head_1_stype}>ปกติ</th>
                                        <th style={head_1_stype}>Extra ผลักดันขาย</th>
                                        <th style={head_1_stype}>COM พิเศษ</th>
                                        {/*==================================================*/}
                                        <th style={head_1_stype}>อุปกรณ์</th>
                                        <th style={head_1_stype}>คอม</th>
                                        <th style={head_1_stype}>ค่ารักษาการขาย</th>
                                        <th style={head_1_stype}>ค่าเคลื่อนย้ายรถ</th>
                                        {/*==================================================*/}
                                        <th style={head_1_stype}>ยอดรับเงิน</th>
                                        <th style={head_1_stype}>ค่าอื่นๆ</th>
                                        <th style={head_1_stype}>เป้าการขาย อื่นๆ</th>
                                        <th style={head_1_stype}>ยอดรวมสุทธิ</th>
                                        {/*==================================================*/}
                                   </tr>
                              </thead>
                              <tbody className="no-bottom">
                                   {state.lastData.length > 0
                                        ? headData.map((element, i) => {
                                               return (
                                                    <tr key={i + 1}>
                                                         <td style={texts}>{i + 1}</td>
                                                         <td style={texts}>{element.sell_name ? element.sell_name : ""}</td>
                                                         <th style={number}>{element.order_old ? element.order_old.toLocaleString() : 0}</th>
                                                         <th style={number}>
                                                              {element.arr_data_length ? element.arr_data_length.toLocaleString() : 0}
                                                         </th>
                                                         <th style={number}>
                                                              {element.sum_sum_car_price ? tofieds(element.sum_sum_car_price) : tofieds(0)}
                                                         </th>
                                                         <th style={number}>{element.sum_extar ? tofieds(element.sum_extar) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_com_x ? tofieds(element.sum_com_x) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_col_w ? tofieds(element.sum_col_w) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_acc_x ? tofieds(element.sum_acc_x) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_com ? tofieds(element.sum_com) : tofieds(0)}</th>
                                                         <th style={number}>{element.maintenance ? tofieds(element.maintenance) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_move_car ? tofieds(element.sum_move_car) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_sum_excel ? tofieds(element.sum_sum_excel) : tofieds(0)}</th>
                                                         <th style={number}>{element.sum_money ? tofieds(element.sum_money) : tofieds(0)}</th>
                                                         <th style={number}>{element.cost_other ? tofieds(element.cost_other) : tofieds(0)}</th>
                                                         <th style={number}>{element.cost_target ? tofieds(element.cost_target) : tofieds(0)}</th>
                                                         <th style={number}>{element.total ? tofieds(element.total) : tofieds(0)}</th>
                                                    </tr>
                                               );
                                          })
                                        : null}
                              </tbody>
                              <thead>
                                   {state.lastData.length > 0 ? (
                                        <tr style={{ whiteSpace: "nowrap" }}>
                                             <th style={head_stype1} colSpan={2}>
                                                  รวม
                                             </th>
                                             <th style={number}>{headData.reduce(add("order_old"), 0).toLocaleString()}</th>
                                             <th style={number}>{headData.reduce(add("arr_data_length"), 0).toLocaleString()}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_sum_car_price"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_extar"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_com_x"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_col_w"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_acc_x"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_com"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("maintenance"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_move_car"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_sum_excel"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("sum_money"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("cost_other"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("cost_target"), 0))}</th>
                                             <th style={number}>{tofieds(headData.reduce(add("total"), 0))}</th>
                                        </tr>
                                   ) : null}
                                   <tr style={{ whiteSpace: "nowrap", paddingTop: 20 }}>
                                        <th style={{ border: "none" }} colSpan={17}>
                                             <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center", marginTop: 75 }}>
                                                  <div>
                                                       <p>
                                                            <label>
                                                                 ลงลายมือชื่อ.............................................................................หัวหน้าทีม
                                                            </label>
                                                       </p>
                                                       <p>
                                                            <label>
                                                                 (.............................................................................)
                                                            </label>
                                                       </p>
                                                       <p className="mb-0">
                                                            <label>
                                                                 วันที่..................../............................/.....................
                                                            </label>
                                                       </p>
                                                  </div>
                                                  <div>
                                                       <p>
                                                            <label>
                                                                 ลงลายมือชื่อ.............................................................................ผู้จัดการฝ่ายขาย
                                                            </label>
                                                       </p>
                                                       <p>
                                                            <label>
                                                                 (.............................................................................)
                                                            </label>
                                                       </p>
                                                       <p className="mb-0">
                                                            <label>
                                                                 วันที่..................../............................/.....................
                                                            </label>
                                                       </p>
                                                  </div>
                                                  <div>
                                                       <p>
                                                            <label>
                                                                 ลงลายมือชื่อ.............................................................................ผู้จัดการทั่วไป
                                                            </label>
                                                       </p>
                                                       <p>
                                                            <label>
                                                                 (.............................................................................)
                                                            </label>
                                                       </p>
                                                       <p className="mb-0">
                                                            <label>
                                                                 วันที่..................../............................/.....................
                                                            </label>
                                                       </p>
                                                  </div>
                                             </div>
                                        </th>
                                   </tr>
                              </thead>
                         </TableBoot>
                    </Col>
                    {state.lastData.length > 0
                         ? state.lastData.map((e, i) => {
                                let cost_other = e.cost_other || 0;
                                let cost_target = e.cost_target || 0;
                                let maintenance = e.maintenance || 0;
                                //===========================================================================//
                                let arr_data = e.arr_data.map((el) => ({
                                     ...el,
                                     extar_pass: el.extar_pass || 0,
                                     margin_use:
                                          (el.sub_down || 0) +
                                          (el.subsidy || 0) +
                                          (el.recommend_price || 0) +
                                          (el.discount_price || 0) +
                                          (el.acc_price || 0) +
                                          (el.insurance || 0) +
                                          (el.register || 0) +
                                          (el.other_pay_price || 0),
                                     package: el.package || 0,
                                     camban: el.camban || 0,
                                     col_t: el.col_t ? Number(el.col_t) : Number(el.col_t) === 0 ? 0 : set_col_t(el),
                                     col_u: el.col_u
                                          ? Number(el.col_u)
                                          : Number(el.col_u) === 0
                                          ? 0
                                          : set_col_uv(el, e.get_car_price[0].percen_company),
                                     col_v: el.col_v ? Number(el.col_v) : Number(el.col_v) === 0 ? 0 : set_col_uv(el, e.get_car_price[0].percen_sale),
                                     col_w: el.col_v ? Number(el.col_v) : Number(el.col_v) === 0 ? 0 : set_col_uv(el, e.get_car_price[0].percen_sale),
                                     extar: el.extar || 0,
                                     com_x: el.com_x || 0,
                                     acc_x: el.acc_x || 0,
                                     com: el.com || 0,
                                     move_car: el.move_car || 0,
                                     sum_excel: el.sum_excel || 0,
                                     total: set_total(
                                          el.col_v ? Number(el.col_v) : Number(el.col_v) === 0 ? 0 : set_col_uv(el, e.get_car_price[0].percen_sale),
                                          el,
                                          e
                                     ),
                                }));
                                //===========================================================================//
                                let sum_margin = arr_data.reduce(add("margin"), 0);
                                let sum_extar_pass = arr_data.reduce(add("extar_pass"), 0);
                                let sum_margin_use = arr_data.reduce(add("margin_use"), 0);
                                let sum_package = arr_data.reduce(add("package"), 0);
                                let sum_camban = arr_data.reduce(add("camban"), 0);
                                let sum_addition_price = arr_data.reduce(add("addition_price"), 0);
                                //===========================================================================//
                                let sum_sub_down = arr_data.reduce(add("sub_down"), 0);
                                let sum_subsidy = arr_data.reduce(add("subsidy"), 0);
                                let sum_recommend_price = arr_data.reduce(add("recommend_price"), 0);
                                let sum_discount_price = arr_data.reduce(add("discount_price"), 0);
                                let sum_acc_price = arr_data.reduce(add("acc_price"), 0);
                                let sum_insurance = arr_data.reduce(add("insurance"), 0);
                                let sum_register = arr_data.reduce(add("register"), 0);
                                let sum_other_pay_price = arr_data.reduce(add("other_pay_price"), 0);
                                //===========================================================================//
                                let sum_col_t = arr_data.reduce(add("col_t"), 0);
                                let sum_col_u = arr_data.reduce(add("col_u"), 0);
                                let sum_col_v = arr_data.reduce(add("col_v"), 0);
                                let sum_col_w = arr_data.reduce(add("col_w"), 0);
                                //===========================================================================//
                                let sum_sum_car_price = e.sum_car_price * arr_data.length;
                                //===========================================================================//
                                let sum_extar = arr_data.reduce(add("extar"), 0);
                                let sum_com_x = arr_data.reduce(add("com_x"), 0);
                                let sum_acc_x = arr_data.reduce(add("acc_x"), 0);
                                let sum_com = arr_data.reduce(add("com"), 0);
                                let sum_move_car = arr_data.reduce(add("move_car"), 0);
                                let sum_sum_excel = arr_data.reduce(add("sum_excel"), 0);
                                let sum_total = arr_data.reduce(add("total"), 0);
                                //===========================================================================//
                                let last_total = Number(sum_total + cost_other + cost_target) - Number(maintenance);
                                return (
                                     <Col className="mt-5" key={i + 1} xs={12}>
                                          <Button
                                               style={{ background: state.edit[e.saler_id] ? "green" : "" }}
                                               onClick={() => {
                                                    let { edit } = this.state;
                                                    //   console.log("edit", edit);
                                                    edit[e.saler_id] = edit[e.saler_id] && edit[e.saler_id] === true ? false : true;
                                                    //   console.log("first", edit[e.saler_id]);
                                                    this.setState({ edit });
                                                    if (!edit[e.saler_id]) {
                                                         this.saveData(i);
                                                    }
                                               }}
                                          >
                                               {state.edit[e.saler_id] ? "เสร็จสิ้น" : "แก้ไข"}
                                          </Button>
                                          {!state.edit[e.saler_id] && (
                                               <Button
                                                    style={{ marginTop: 5, background: "#c70606", borderColor: "#c70606" }}
                                                    onClick={() => this.handleExport(i)}
                                               >
                                                    พิมพ์ PDF ( {e.sell_name} )
                                               </Button>
                                          )}
                                          <TableBoot id={`${rootContainerId}-${i + 1}`} style={{ background: "white" }} bordered hover responsive>
                                               <thead>
                                                    <tr>
                                                         <th colSpan={32} style={{ fontSize: "1.5rem" }}>
                                                              <Row style={{ minWidth: 955 }}>
                                                                   <Col xs={2}>ค่าคอมมิชชั่นเดือน</Col>
                                                                   <Col xs={10}>
                                                                        :{" "}
                                                                        {`${dayjs(props.date_end).locale("th").format("MMMM")} ${
                                                                             dayjs(props.date_end).year() + 543
                                                                        }`}
                                                                   </Col>
                                                              </Row>
                                                              <Row style={{ minWidth: 955 }}>
                                                                   <Col xs={2}>สาขา</Col>
                                                                   <Col xs={10}>: {props.branch_name}</Col>
                                                              </Row>
                                                              <Row style={{ minWidth: 955 }}>
                                                                   <Col xs={2}>ทีมขาย</Col>
                                                                   <Col xs={10}>: {props.team_name}</Col>
                                                              </Row>
                                                              <Row style={{ minWidth: 955 }}>
                                                                   <Col xs={2}>ชื่อพนักงานขาย</Col>
                                                                   <Col xs={10}>: {e.sell_name}</Col>
                                                              </Row>
                                                              <Row style={{ minWidth: 955 }}>
                                                                   <Col xs={2}>จำนวนส่งมอบ</Col>
                                                                   <Col xs={10}>: {arr_data.length}</Col>
                                                              </Row>
                                                         </th>
                                                    </tr>
                                               </thead>
                                               <thead>
                                                    <tr style={{ whiteSpace: "break-spaces" }}>
                                                         <th style={head_stype} colSpan={12}>
                                                              รายละเอียด
                                                         </th>
                                                         <th style={head_stype} colSpan={8}>
                                                              ใช้มาจิ้น
                                                         </th>
                                                         <th style={head_stype} colSpan={4}>
                                                              มาจิ้นคงเหลือ
                                                         </th>
                                                         <th style={head_stype} colSpan={3}>
                                                              Com พนักงานขาย
                                                         </th>
                                                         <th style={head_stype} colSpan={3}>
                                                              รายการหัก
                                                         </th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_stype, minWidth: 110 } : head_stype}
                                                              rowSpan={2}
                                                         >
                                                              บวกรับจาก excel
                                                         </th>
                                                         <th style={head_stype} rowSpan={2}>
                                                              ยอดเงิน รับสุทธิ
                                                         </th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: "break-spaces" }}>
                                                         <th style={head_1_stype}>ลำดับ</th>
                                                         <th style={head_1_stype}>เลขที่ใบจอง</th>
                                                         <th style={head_1_stype}>วันส่งมอบ</th>
                                                         <th style={{ ...head_1_stype, minWidth: 115 }}>ชื่อลูกค้า</th>
                                                         <th style={{ ...head_1_stype, minWidth: 105 }}>รุ่นรถ</th>
                                                         <th style={{ ...head_1_stype, minWidth: 105 }}>โมเดล</th>
                                                         <th style={head_1_stype}>{`มาจิ้น\nทั้งหมด`}</th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`Extar\nอนุมัติ`}</th>
                                                         <th style={head_1_stype}>{`มาจิ้นใช้\nไปทั้งหมด`}</th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`เพจเกจ\nแคมเปญ`}</th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`แคมแบญ\nพิเศษ`}</th>
                                                         <th style={head_1_stype}>บวกราคา</th>
                                                         {/*==================================================*/}
                                                         <th style={head_1_stype}>ซัพดาวน์</th>
                                                         <th style={head_1_stype}>Subsidy</th>
                                                         <th style={head_1_stype}>ค่าแนะนำ</th>
                                                         <th style={head_1_stype}>{`ส่วนลด\nเงินสด`}</th>
                                                         <th style={head_1_stype}>อุปกรณ์</th>
                                                         <th style={head_1_stype}>ประกันภัย</th>
                                                         <th style={head_1_stype}>จดทะเบียน</th>
                                                         <th style={head_1_stype}>ค่าอื่นๆ</th>
                                                         {/*==================================================*/}
                                                         <th style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}>
                                                              100%
                                                         </th>
                                                         <th style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}>
                                                              บริษัท
                                                         </th>
                                                         {/* <th style={head_1_stype}>บริษัท({e.get_car_price[0].percen_company}%)</th> */}
                                                         <th style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}>
                                                              พนักงานขาย
                                                         </th>
                                                         {/* <th style={head_1_stype}>พนักงานขาย({e.get_car_price[0].percen_sale}%)</th> */}
                                                         <th style={head_1_stype}>คงเหลือ</th>
                                                         {/*==================================================*/}
                                                         <th style={head_1_stype}>{`ปกติ\nรายคัน`}</th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`Extar\nผลักดันขาย`}</th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`COM\nพิเศษ`}</th>
                                                         {/*==================================================*/}
                                                         <th style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}>
                                                              อุปกรณ์
                                                         </th>
                                                         <th style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}>
                                                              คอม
                                                         </th>
                                                         <th
                                                              style={state.edit[e.saler_id] ? { ...head_1_stype, minWidth: 110 } : head_1_stype}
                                                         >{`ค่าเคลื่อน\nย้ายรถ`}</th>
                                                    </tr>
                                               </thead>
                                               <tbody className="no-bottom">
                                                    {arr_data.map((el, ind) => (
                                                         <tr key={ind + 1}>
                                                              <td style={texts}>{ind + 1}</td>
                                                              <td style={{ whiteSpace: "nowrap", ...texts2 }}>
                                                                   {el.order_code_id ? el.order_code_id : ""}
                                                              </td>
                                                              <td style={{ whiteSpace: "nowrap", ...texts2 }}>
                                                                   {el.delivery_date ? el.delivery_date : ""}
                                                              </td>
                                                              <td style={texts2}>{el.userinfo_name ? el.userinfo_name : ""}</td>
                                                              <td style={texts}>{el.series_name ? el.series_name : ""}</td>
                                                              <td style={texts}>{el.model_name ? el.model_name : ""}</td>
                                                              <th style={number}>{el.margin ? tofieds(el.margin) : tofieds(0)}</th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.extar_pass}
                                                                                  type={"number"}
                                                                                  name={"extar_pass"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "extar_pass")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.extar_pass || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>{tofieds(el.margin_use)}</th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.package}
                                                                                  type={"number"}
                                                                                  name={"package"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "package")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.package || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.camban}
                                                                                  type={"number"}
                                                                                  name={"camban"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "camban")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.camban || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>{el.addition_price ? tofieds(el.addition_price) : tofieds(0)}</th>
                                                              {/*==================================================*/}
                                                              {/*==================================================*/}

                                                              <th style={number}>{el.sub_down ? tofieds(el.sub_down) : tofieds(0)}</th>
                                                              <th style={number}>{el.subsidy ? tofieds(el.subsidy) : tofieds(0)}</th>
                                                              <th style={number}>{el.recommend_price ? tofieds(el.recommend_price) : tofieds(0)}</th>
                                                              <th style={number}>{el.discount_price ? tofieds(el.discount_price) : tofieds(0)}</th>
                                                              <th style={number}>{el.acc_price ? tofieds(el.acc_price) : tofieds(0)}</th>
                                                              <th style={number}>{el.insurance ? tofieds(el.insurance) : tofieds(0)}</th>
                                                              <th style={number}>{el.register ? tofieds(el.register) : tofieds(0)}</th>
                                                              <th style={number}>{el.other_pay_price ? tofieds(el.other_pay_price) : tofieds(0)}</th>

                                                              {/*==================================================*/}
                                                              {/*==================================================*/}
                                                              {/* <th style={number}>{el.col_t ? tofieds(el.col_t) : tofieds(0)}</th> */}
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.col_t}
                                                                                  type={"number"}
                                                                                  name={"col_t"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "col_t")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.col_t || 0)
                                                                   )}
                                                              </th>
                                                              {/* <th style={number}>{el.col_u ? tofieds(el.col_u) : tofieds(0)}</th> */}
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.col_u}
                                                                                  type={"number"}
                                                                                  name={"col_u"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "col_u")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.col_u || 0)
                                                                   )}
                                                              </th>
                                                              {/* <th style={number}>{el.col_v ? tofieds(el.col_v) : tofieds(0)}</th> */}
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.col_v}
                                                                                  type={"number"}
                                                                                  name={"col_v"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "col_v")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.col_v || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>{el.col_w ? tofieds(el.col_w) : tofieds(0)}</th>
                                                              {/*==================================================*/}
                                                              {/*==================================================*/}
                                                              {/* <th style={number}>{e.sum_car_price ? tofieds(e.sum_car_price) : tofieds(0)}</th> */}
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={e.sum_car_price}
                                                                                  type={"number"}
                                                                                  name={"sum_car_price"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, "not", "sum_car_price")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(e.sum_car_price || 0)
                                                                   )}
                                                              </th>
                                                              {/*==================================================*/}
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.extar}
                                                                                  type={"number"}
                                                                                  name={"extar"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "extar")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.extar || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.com_x}
                                                                                  type={"number"}
                                                                                  name={"com_x"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "com_x")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.com_x || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.acc_x}
                                                                                  type={"number"}
                                                                                  name={"acc_x"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "acc_x")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.acc_x || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.com}
                                                                                  type={"number"}
                                                                                  name={"com"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "com")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.com || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.move_car}
                                                                                  type={"number"}
                                                                                  name={"move_car"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "move_car")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.move_car || 0)
                                                                   )}
                                                              </th>
                                                              <th style={number}>
                                                                   {state.edit[e.saler_id] ? (
                                                                        <Form.Row>
                                                                             <Field
                                                                                  value={el.sum_excel}
                                                                                  type={"number"}
                                                                                  name={"sum_excel"}
                                                                                  xs="auto"
                                                                                  handleChangeNumber={(value) =>
                                                                                       this.handleChangeEditCol(value, i, ind, "sum_excel")
                                                                                  }
                                                                             />
                                                                        </Form.Row>
                                                                   ) : (
                                                                        tofieds(el.sum_excel || 0)
                                                                   )}
                                                              </th>
                                                              {/*==================================================*/}

                                                              <th style={number}>{el.total ? tofieds(el.total) : tofieds(0)}</th>
                                                         </tr>
                                                    ))}
                                               </tbody>
                                               <thead>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                         <th style={head_stype1} colSpan={6}>
                                                              รวม
                                                         </th>
                                                         <th style={number}>{tofieds(sum_margin)}</th>
                                                         <th style={number}>{tofieds(sum_extar_pass)}</th>
                                                         <th style={number}>{tofieds(sum_margin_use)}</th>
                                                         <th style={number}>{tofieds(sum_package)}</th>
                                                         <th style={number}>{tofieds(sum_camban)}</th>
                                                         <th style={number}>{tofieds(sum_addition_price)}</th>
                                                         <th style={number}>{tofieds(sum_sub_down)}</th>
                                                         <th style={number}>{tofieds(sum_subsidy)}</th>
                                                         <th style={number}>{tofieds(sum_recommend_price)}</th>
                                                         <th style={number}>{tofieds(sum_discount_price)}</th>
                                                         <th style={number}>{tofieds(sum_acc_price)}</th>
                                                         <th style={number}>{tofieds(sum_insurance)}</th>
                                                         <th style={number}>{tofieds(sum_register)}</th>
                                                         <th style={number}>{tofieds(sum_other_pay_price)}</th>
                                                         <th style={number}>{tofieds(sum_col_t)}</th>
                                                         <th style={number}>{tofieds(sum_col_u)}</th>
                                                         <th style={number}>{tofieds(sum_col_v)}</th>
                                                         <th style={number}>{tofieds(sum_col_w)}</th>
                                                         <th style={number}>{tofieds(sum_sum_car_price)}</th>
                                                         <th style={number}>{tofieds(sum_extar)}</th>
                                                         <th style={number}>{tofieds(sum_com_x)}</th>
                                                         <th style={number}>{tofieds(sum_acc_x)}</th>
                                                         <th style={number}>{tofieds(sum_com)}</th>
                                                         <th style={number}>{tofieds(sum_move_car)}</th>
                                                         <th style={number}>{tofieds(sum_sum_excel)}</th>
                                                         <th style={number}>{tofieds(sum_total)}</th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                         <th style={{ border: "none" }} colSpan={20} rowSpan={4} />
                                                         <th className="pb-0" style={{ border: "none", textAlign: "center" }} colSpan={7} rowSpan={4}>
                                                              {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                                   <th style={{ border: "none" }} colSpan={27} />
                                                                   <th style={{ ...head_stype, border: "none" }} colSpan={5}> */}
                                                              <p>
                                                                   <label>
                                                                        ลงลายมือชื่อ.............................................................................พนักงานขาย
                                                                   </label>
                                                              </p>
                                                              <p>
                                                                   <label>
                                                                        (.............................................................................)
                                                                   </label>
                                                              </p>
                                                              <p className="mb-0">
                                                                   <label>
                                                                        วันที่..................../............................/.....................
                                                                   </label>
                                                              </p>
                                                              {/* </th>
                                                              </tr> */}
                                                         </th>
                                                         <th style={head_stype1} colSpan={4}>
                                                              บวก ค่าอื่นๆ
                                                         </th>
                                                         <th style={state.edit[e.saler_id] ? { ...number, minWidth: 110 } : number} colSpan={1}>
                                                              {state.edit[e.saler_id] ? (
                                                                   <Form.Row>
                                                                        <Field
                                                                             value={cost_other}
                                                                             type={"number"}
                                                                             name={"cost_other"}
                                                                             xs="auto"
                                                                             handleChangeNumber={(value) =>
                                                                                  this.handleChangeEditCol(value, i, "not", "cost_other")
                                                                             }
                                                                        />
                                                                   </Form.Row>
                                                              ) : (
                                                                   tofieds(cost_other || 0)
                                                              )}
                                                         </th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                         <th style={head_stype1} colSpan={4}>
                                                              บวก ค่าเป้าการขายอื่นๆ
                                                         </th>
                                                         <th style={state.edit[e.saler_id] ? { ...number, minWidth: 110 } : number} colSpan={1}>
                                                              {state.edit[e.saler_id] ? (
                                                                   <Form.Row>
                                                                        <Field
                                                                             value={cost_target}
                                                                             type={"number"}
                                                                             name={"cost_target"}
                                                                             xs="auto"
                                                                             handleChangeNumber={(value) =>
                                                                                  this.handleChangeEditCol(value, i, "not", "cost_target")
                                                                             }
                                                                        />
                                                                   </Form.Row>
                                                              ) : (
                                                                   tofieds(cost_target || 0)
                                                              )}
                                                         </th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                         <th style={head_stype1} colSpan={4}>
                                                              หัก ค่ารักษาการขาย
                                                         </th>
                                                         <th style={state.edit[e.saler_id] ? { ...number, minWidth: 110 } : number} colSpan={1}>
                                                              {state.edit[e.saler_id] ? (
                                                                   <Form.Row>
                                                                        <Field
                                                                             value={maintenance}
                                                                             type={"number"}
                                                                             name={"maintenance"}
                                                                             xs="auto"
                                                                             handleChangeNumber={(value) =>
                                                                                  this.handleChangeEditCol(value, i, "not", "maintenance")
                                                                             }
                                                                        />
                                                                   </Form.Row>
                                                              ) : (
                                                                   tofieds(maintenance || 0)
                                                              )}
                                                         </th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                         <th style={head_stype} colSpan={4}>
                                                              รับสุทธิ
                                                         </th>
                                                         <th style={number} colSpan={1}>
                                                              {last_total ? tofieds(last_total) : tofieds(0)}
                                                         </th>
                                                    </tr>
                                               </thead>
                                          </TableBoot>
                                     </Col>
                                );
                           })
                         : null}
                    <Col xs={12}>
                         {/* <Button
                              style={{ marginTop: 15, background: state.edit ? "green" : "" }}
                              onClick={() => this.setState({ edit: !state.edit })}
                         >
                              {state.edit ? "เสร็จสิ้น" : "แก้ไข"}
                         </Button> */}
                         {/* {!state.edit && (
                              <Button style={{ marginTop: 5, background: "#c70606", borderColor: "#c70606" }} onClick={this.handleExportAll}>
                                   พิมพ์ PDF
                              </Button>
                         )} */}
                    </Col>
               </Row>
          );
     }
}
const set_col_t = (el) => {
     return Number((el.margin || 0) + (el.extar_pass || 0) + (el.package || 0) + (el.camban || 0) + (el.addition_price || 0)) -
          Number(
               (el.sub_down || 0) +
                    (el.subsidy || 0) +
                    (el.recommend_price || 0) +
                    (el.discount_price || 0) +
                    (el.acc_price || 0) +
                    (el.insurance || 0) +
                    (el.register || 0) +
                    (el.other_pay_price || 0)
          ) >=
          0
          ? Number((el.margin || 0) + (el.extar_pass || 0) + (el.package || 0) + (el.camban || 0) + (el.addition_price || 0)) -
                 Number(
                      (el.sub_down || 0) +
                           (el.subsidy || 0) +
                           (el.recommend_price || 0) +
                           (el.discount_price || 0) +
                           (el.acc_price || 0) +
                           (el.insurance || 0) +
                           (el.register || 0) +
                           (el.other_pay_price || 0)
                 )
          : 0;
};
const set_col_uv = (el, percen) => {
     return Number((el.margin || 0) + (el.extar_pass || 0) + (el.package || 0) + (el.camban || 0) + (el.addition_price || 0)) -
          Number(
               (el.sub_down || 0) +
                    (el.subsidy || 0) +
                    (el.recommend_price || 0) +
                    (el.discount_price || 0) +
                    (el.acc_price || 0) +
                    (el.insurance || 0) +
                    (el.register || 0) +
                    (el.other_pay_price || 0)
          ) >=
          0
          ? ((Number((el.margin || 0) + (el.extar_pass || 0) + (el.package || 0) + (el.camban || 0) + (el.addition_price || 0)) -
                 Number(
                      (el.sub_down || 0) +
                           (el.subsidy || 0) +
                           (el.recommend_price || 0) +
                           (el.discount_price || 0) +
                           (el.acc_price || 0) +
                           (el.insurance || 0) +
                           (el.register || 0) +
                           (el.other_pay_price || 0)
                 )) *
                 percen) /
                 100
          : 0;
};
const set_total = (col_w, el, e) => {
     return (
          Number(Number(col_w) + Number(e.sum_car_price) + Number(el.extar || 0) + Number(el.com_x || 0)) -
          Number(el.acc_x || 0) -
          Number(el.com || 0) -
          Number(el.move_car || 0) +
          Number(el.sum_excel || 0)
     );
};

const rootContainerId = "widget-container";
const iconButton = "exportIconButton";
const filter = (node) => node.id !== iconButton;
const exportToPdf = async (chart) => {
     const width = chart.offsetWidth;
     const height = chart.offsetHeight;
     try {
          const dataUrl = await domtoimage.toJpeg(chart, { filter });
          // @ts-ignore
          const doc = new JsPDF({
               orientation: "landscape",
               unit: "px",
               format: [width, height],
          });
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = doc.internal.pageSize.getHeight();
          doc.addImage(dataUrl, "JPEG", 0, 0, pdfWidth, pdfHeight);
          // doc.save("pdf");
          doc.output("dataurlnewwindow");
     } catch (err) {
          // eslint-disable-next-line no-console
          console.error("oops, something went wrong!", err);
     }
};
