import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img } from "components/index";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { GET, POST } from "api/index";
import dayjs from "dayjs";
import swal from "sweetalert";
import { getToken_permission_page } from "../../../../../static/function";

export default class Car extends Component {
     constructor(props) {
          super(props);
          this.state = {
               carmanage: [],
               fileTextHolder1: "",
               fileTextHolder2: "",
               fileTextHolder3: "",
               fileTextHolderInner: "",
               search: [],
               series: [],
               model: [],
               series_id: null,
               colors: [],
               series_name_search: "",
               model_name_search: "",
               color_name_search: "",
               car: this.props.location.car,
               loading: false,
               loading2: false,
          };
     }
     componentDidMount = () => {
          this.getCar();
          this.getSeries();
          // this.getModel();
     };
     getCar = async () => {
          try {
               let result = await GET("/car/get");
               this.setState({ carmanage: result });
          } catch (error) {
               console.log(error);
          }
     };

     handleChange = (event, name) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    [name]: fileUploaded.name,
               });
          }
     };
     handleChange2 = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    fileInner: fileUploaded,
                    fileTextHolderInner: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async (item) => {
          const { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", item === "vin" ? "car_master" : item);
                    formData.append("file", file);

                    this.setState({ loading: true });
                    if (item === "vin") {
                         await POST("/aos/car_master", formData, true);
                         window.location.replace("/admin/car-upload");
                    } else {
                         let res = await POST("/aos/" + item, formData, true);
                         this.setState({ loading: false });
                         swal({
                              text: res,
                              icon: "success",
                              button: "ตกลง",
                         });
                    }
               }
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };
     handleUploadFile2 = async () => {
          const { fileInner } = this.state;
          try {
               let formData = new FormData();
               if (fileInner) {
                    formData.append("type", "inner_master");
                    formData.append("file", fileInner);

                    this.setState({ loading2: true });
                    let res = await POST("/aos/inner_master", formData, true);
                    this.setState({ loading2: false });
                    swal({
                         text: res,
                         icon: "success",
                         button: "ตกลง",
                    });
               }
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading2: false });
          }
     };

     handleChangeText = async (e) => {
          let { search, series_id, series } = this.state;
          search[e.target.name] = e.target.value;
          let name = e.target.name;

          if (name === "car_series") {
               let series_id = Number(e.target.value);
               this.getModel(series_id);
               // console.log(name);
          }

          if (name === "car_model") {
               let model_id = Number(e.target.value);
               this.getColors(model_id);
               // console.log(model_id)
          }

          // console.log("e", e.target.name);
          this.setState({ ...search });
          // console.log("search", search);
     };

     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({ ...search });
     };

     handleClick = () => {
          window.location.assign("/admin/car/add");
     };

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <i className="fas fa-edit" onClick={() => window.location.assign(`/admin/car/edit/${row.car_id}`)}></i>;
     }

     getSeries = async () => {
          let { series_id } = this.state;
          try {
               let series = await GET("/car/series");
               this.setState({ series: [...series] });
          } catch (error) {
               console.log(error);
          }
     };

     getModel = async (series_id) => {
          let { car, search } = this.state;
          try {
               let model = await POST("/car/model", { series_id });
               //  console.log("model", model);
               this.setState({ model: model });
          } catch (error) {
               console.log(error);
          }
     };

     getColors = async (model_id) => {
          try {
               let colors = await POST("/car/color", { model_id });
               this.setState({ colors });
               console.log(colors);
          } catch (error) {
               console.log(error);
          }
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };

     handleChangeCar = ({ target: { name, value } }) => {
          let { search, series, model, colors } = this.state;
          // console.log("name", name);
          if (name === "car_series") {
               /// owner
               search[name] = series.find((e) => e.series_id === Number(value)).series_id;
               let serieName = series.find((e) => e.series_id === Number(value)).series_name;

               search.car_model = "";
               search.car_color = "";
               this.getModel(value, name);
               this.setState({ series_name_search: serieName });
          } else if (name === "car_model") {
               search[name] = model.find((e) => e.model_id === Number(value)).model_id;
               let model_name_search = model.find((e) => e.model_id === Number(value)).model_name;

               this.getColors(value, name);
               // console.log("model", model_name_search);
               this.setState({ model_name_search: model_name_search });
          } else if (name === "car_color") {
               //  console.log("colorrrrrrrr");
               search[name] = colors.find((e) => e.color_id === Number(value)).color_id;
               let color_name_search = colors.find((e) => e.color_id === Number(value)).color_name;

               // console.log("color", color_name_search);
               this.setState({ color_name_search: color_name_search });
          } else {
               // console.log("first", name, value);
               search[name] = value;
          }

          this.setState({ search });
          // console.log("search", search);
     };

     handleClickSearch = (item) => {
          let { search, carmanage, series_name_search, model_name_search, color_name_search } = this.state;
          this.props.history.push({
               pathname: `/admin/car/search/list/car`,
               state: {
                    item,
                    car_engine: search.car_engine,
                    car_tank: search.car_tank,
                    car_date: search.car_date,
                    car_type: search.car_type,
                    car_series: series_name_search,
                    car_model: model_name_search,
                    car_color: color_name_search,
                    // year: search.year,
                    // month: search.month,
                    car_engine_success: search.car_engine_success,
                    car_tank_success: search.car_tank_success,
                    order_code_id: search.order_code_id,
               },
          });
     };
     downloadFile = async (item) => {
          try {
               window.open(await POST("/ps/download_excel", { download: item }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     downloadFile2 = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: "inner" }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     render() {
          let {
               fileTextHolder1,
               fileTextHolder2,
               fileTextHolder3,
               fileTextHolderInner,
               type1,
               search,
               series,
               model,
               colors,
               series_name_search,
               car,
               loading,
               loading2,
          } = this.state;
          // console.log("car",car)
          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path === "/admin/car")[0].add || false;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "สต็อกรถยนต์" }]}
                         active={0}
                         button={
                              addBtn
                                   ? [
                                          {
                                               icon: "fas fa-user-plus -icon",
                                               name: "เพิ่มรถ",
                                               variant: "primary",
                                               handleClick: this.handleClick,
                                          },
                                     ]
                                   : ""
                         }
                    />
                    <div className="content __main-panel">
                         {/* <div className="content __input-panel"> */}
                         <div className="search-edit" style={{ width: "100%" }}>
                              <Search
                                   type={"car"}
                                   handleChangeText={this.handleChangeText}
                                   handleSelectDate={this.handleChangeDate}
                                   handleChangeCar={this.handleChangeCar}
                                   handleClickSearch={this.handleClickSearch}
                                   option={{
                                        car_series: series,
                                        car_model: model,
                                        car_color: colors,
                                        value: {
                                             car_type: search.car_type,
                                             car_series: search.car_series,
                                             car_model: search.car_model,
                                             car_color: search.car_color,
                                             // year: search.year,
                                             // month: search.month,
                                             car_engine_success: search.car_engine_success,
                                             car_tank_success: search.car_tank_success,
                                             order_code_id: search.order_code_id,
                                        },
                                   }}
                                   addBtn
                              />
                              <Row>
                                   <Col sm={12} md={4}>
                                        {loading ? (
                                             <div style={{ textAlign: "center", flex: 1, marginTop: 20 }}>
                                                  <Spinner animation="border" variant="primary" />
                                             </div>
                                        ) : (
                                             <div>
                                                  <Search
                                                       type={"upload"}
                                                       title={{ icon: Img.excel, name: "อัปโหลด Pre-Assi" }}
                                                       placeholder={fileTextHolder1 !== "" ? fileTextHolder1 : "ไฟล์.csv"}
                                                       button={"แนบไฟล์"}
                                                       style_id={"custom-btn __beside-btn noPadding"}
                                                       handleChange={(e) => this.handleChange(e, "fileTextHolder1")}
                                                       handleUploadFile={() => this.handleUploadFile("preassi")}
                                                       downloadFile={() => this.downloadFile("preassi")}
                                                       disabled={loading}
                                                       style={{ marginTop: 20 }}
                                                  />
                                             </div>
                                        )}
                                   </Col>
                                   <Col sm={12} md={4}>
                                        {loading ? (
                                             <div style={{ textAlign: "center", flex: 1, marginTop: 20 }}>
                                                  <Spinner animation="border" variant="primary" />
                                             </div>
                                        ) : (
                                             <div>
                                                  <Search
                                                       type={"upload"}
                                                       title={{ icon: Img.excel, name: "อัปโหลด Assi" }}
                                                       placeholder={fileTextHolder2 !== "" ? fileTextHolder2 : "ไฟล์.csv"}
                                                       button={"แนบไฟล์"}
                                                       style_id={"custom-btn __beside-btn noPadding"}
                                                       handleChange={(e) => this.handleChange(e, "fileTextHolder2")}
                                                       handleUploadFile={() => this.handleUploadFile("assi")}
                                                       downloadFile={() => this.downloadFile("assi")}
                                                       disabled={loading}
                                                       style={{ marginTop: 20 }}
                                                  />
                                             </div>
                                        )}
                                   </Col>
                                   <Col sm={12} md={4}>
                                        {loading ? (
                                             <div style={{ textAlign: "center", flex: 1, marginTop: 20 }}>
                                                  <Spinner animation="border" variant="primary" />
                                             </div>
                                        ) : (
                                             <div>
                                                  <Search
                                                       type={"upload"}
                                                       title={{ icon: Img.excel, name: "อัปโหลด Vin" }}
                                                       placeholder={fileTextHolder3 !== "" ? fileTextHolder3 : "ไฟล์.csv"}
                                                       button={"แนบไฟล์"}
                                                       style_id={"custom-btn __beside-btn noPadding"}
                                                       handleChange={(e) => this.handleChange(e, "fileTextHolder3")}
                                                       handleUploadFile={() => this.handleUploadFile("vin")}
                                                       downloadFile={() => this.downloadFile("vin")}
                                                       disabled={loading}
                                                       style={{ marginTop: 20 }}
                                                  />
                                             </div>
                                        )}
                                   </Col>
                                   <Col sm={12}>
                                        {loading2 ? (
                                             <div style={{ textAlign: "center", flex: 1, marginTop: 20 }}>
                                                  <Spinner animation="border" variant="primary" />
                                             </div>
                                        ) : (
                                             <div>
                                                  <Search
                                                       type={"upload"}
                                                       title={{ icon: Img.excel, name: "อัปโหลด INNER" }}
                                                       placeholder={fileTextHolderInner !== "" ? fileTextHolderInner : "ไฟล์.csv"}
                                                       button={"แนบไฟล์"}
                                                       style_id={"custom-btn __beside-btn noPadding"}
                                                       handleChange={(e) => this.handleChange2(e, "file")}
                                                       handleUploadFile={this.handleUploadFile2}
                                                       downloadFile={this.downloadFile2}
                                                       disabled={loading2}
                                                       style={{ marginTop: 20 }}
                                                  />
                                             </div>
                                        )}
                                   </Col>
                              </Row>
                         </div>
                    </div>
               </div>
          );
     }
}
