import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table } from "components/index";
import { Form, Row, Col, Button } from "react-bootstrap";
import { GET, POST } from "../../../../../api/api";
import swal from "sweetalert";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { detail_car } from "../../../../../static/Static";
dayjs.extend(weekday);
dayjs.extend(localeData);
export default class ManageCar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.manage,
               title: "",
               car: {
                    status: "ใช้งาน",
                    promotions: [],
                    // deletePromotion: [],
                    price_list: {},
               },
               series: [],
               model: [],
               colors: [],
               promotion: [],
               functions_yes: [],
               title: "",
               serie: {
                    status: "ใช้งาน",
                    model: [],
               },
               addEditPromotion: [],
               show: false,
               promotions_data: [],
               promotion_type: "",
               pro_id: "",
               carPrice: null,
               promotion_id: [],
               deletePromotion: [],
               dateValue: {},
          };
     }

     componentDidMount = () => {
          this.setPath();
          this.getSeries();
     };

     setPath = async () => {
          let { previous_path, title } = this.state;
          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "รายการรถยนต์",
                              onClick: () => window.location.assign("/admin/car"),
                         },
                         {
                              title: "เพิ่มรถ",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         {
                              title: "รายการรถยนต์",
                              onClick: () => window.location.assign("/admin/car"),
                         },
                         {
                              title: "แก้ไข",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    this.getCar();
                    break;
               default:
                    break;
          }
          title = this.setState({ title: title });
     };

     getCar = async () => {
          let { dateValue } = this.state;
          try {
               let car = await POST("/car/this", { car_id: this.props.match.params.id });
               if (this.state.previous_path === "edit") {
                    car.car_create = dayjs(car.car_create);
                    car.INVOICE_DATE = car.INVOICE_DATE ? dayjs(car.INVOICE_DATE) : "";
                    car.DUE_DATE = car.DUE_DATE ? dayjs(car.DUE_DATE) : "";
                    car.SCH_DATE = car.SCH_DATE ? dayjs(car.SCH_DATE) : "";
                    car.FINAL_ASSIGN = car.FINAL_ASSIGN ? dayjs(car.FINAL_ASSIGN) : "";
                    car.TMT_DEPARTURE_DATE = car.TMT_DEPARTURE_DATE ? dayjs(car.TMT_DEPARTURE_DATE) : "";
                    dateValue.car_create = dayjs(car.car_create);
                    dateValue.INVOICE_DATE = car.INVOICE_DATE ? dayjs(car.INVOICE_DATE) : "";
                    dateValue.DUE_DATE = car.DUE_DATE ? dayjs(car.DUE_DATE) : "";
                    dateValue.SCH_DATE = car.SCH_DATE ? dayjs(car.SCH_DATE) : "";
                    dateValue.FINAL_ASSIGN = car.FINAL_ASSIGN ? dayjs(car.FINAL_ASSIGN) : "";
                    dateValue.TMT_DEPARTURE_DATE = car.TMT_DEPARTURE_DATE ? dayjs(car.TMT_DEPARTURE_DATE) : "";
                    dateValue.car_year = car.car_year ? dayjs(car.car_year) : "";
                    let acc_filter = car.acc.filter((el, i) => car.acc.map((ele) => ele.items_name).indexOf(el.items_name) === i);
                    car.acc = acc_filter;
                    car.price_list = car.price_list && car.price_list.length > 2 ? JSON.parse(car.price_list) : {};
                    car.acc_installed = car.acc_installed && car.acc_installed.length > 2 ? JSON.parse(car.acc_installed) : [];
               }
               this.getModel(car.series_id);
               this.getColors(car.model_id);
               this.setState({ car: { ...car }, dateValue });
          } catch (error) {
               console.log(error);
          }
     };

     getPromotion = async (series_id) => {
          try {
               this.setState({
                    promotion: [...(await POST("/ps/get_promotion", { series_id }))],
               });
          } catch (error) {
               console.log(error);
          }
     };

     getSeries = async () => {
          let { car } = this.state;
          try {
               let series = await GET("/car/series");
               this.setState({ series: [...series] });
          } catch (error) {
               console.log(error);
          }
     };

     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { car } = this.state;
          car[name] = value;

          if (name === "series_id") {
               car.model_id = "";
               car.color_id = "";
               this.setState({ car, model: [], colors: [] });
               this.getModel(value);
               this.getPromotion(value);
          }
          if (name === "model_id") {
               car.color_id = "";
               this.setState({ colors: [], car });
               this.getColors(value);
          }
          if (name === "color_id") {
               this.getPrice(car.series_id, car.model_id, value);
          }

          this.setState({ car });
     };

     handleChangeNumber = (value, name) => {
          // console.log("value", value.value, "name", name);
          let { car } = this.state;

          // let arr = value.split(",");
          // let number_str = "";

          // for (let i in arr) {
          //   number_str += arr[i];
          // }
          // let number = parseInt(number_str);
          // console.log("number  : ", number);
          car[name] = Number(value.value);
          // if (name === "order_price") {
          //      order.order_vat = (value.value * 7) / 107;

          // }
          // console.log("number",number)
          this.setState({ car });
     };

     handleChangePromotionType = ({ target: { name, value } }) => {
          const { car, pro_id } = this.state;
          // console.log(value);
          // car.promotionValue = "";
          this.setState({ promotion_type: value, pro_id: "" }, () => this.getPromotions());
     };

     getPromotions = async () => {
          const { promotion_type } = this.state;

          try {
               let promotion = await POST("/ps/get_promotion_by_type", {
                    promotion_type,
               });
               this.setState({ promotions_data: promotion });
          } catch (error) {
               console.log(error);
          }
     };

     handleChangePromotion = ({ target: { name, value } }) => {
          const { car, pro_id } = this.state;
          // console.log("value", value);
          // car.promotionValue = value;

          this.setState({ pro_id: value });
     };

     getModel = async (series_id) => {
          let { car } = this.state;
          try {
               let model = await POST("/car/model", { series_id });
               this.setState({ model });
               // this.setState({ model, car });
          } catch (error) {
               console.log(error);
          }
     };

     getColors = async (model_id) => {
          try {
               let colors = await POST("/car/color", { model_id });
               // console.log("colors", colors);
               this.setState({ colors });
          } catch (error) {
               console.log(error);
          }
     };

     // getPrice = (value) => {
     //      let { model, car } = this.state;
     //      if (value) {
     //           console.log(`model`, model)
     //           let findPrice = model.find((e) => e.model_id === Number(value));
     //           car.car_price = findPrice.model_price;
     //      } else {
     //           car.car_price = null;
     //      }
     //      console.log(`car.car_price`, car.car_price)
     //      this.setState({ car });
     // };

     getPrice = async (series_id, model_id, color_id) => {
          let { car } = this.state;
          try {
               let res = await POST("/aos/get_price", { series_id: Number(series_id), model_id: Number(model_id), color_id: Number(color_id) });
               car.car_price = res.price;
               car.car_fun = res.cost;
               car.car_vat = ((Number(res.price) * 7) / 107 + Number(res.price)).toFixed(2);
               this.setState({ car });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleSelectDate = (date, dateString, name) => {
          let { car, dateValue } = this.state;

          if (date === null) {
               car[name] = "";
               dateValue[name] = "";
               this.setState({ car, dateValue });
          } else {
               car[name] = date;
               dateValue[name] = date;
               this.setState({ car, dateValue });
          }
     };

     handleChangeYear = (date, dateString) => {
          let { car, dateValue } = this.state;

          if (date === null) {
               car.car_year = "";
               dateValue.car_year = "";
               this.setState({ car, dateValue });
          } else {
               car.car_year = dateString;
               dateValue.car_year = date;
               this.setState({ car, dateValue });
          }
     };

     handleSubmit = async (event) => {
          event.preventDefault();

          let { car } = this.state;

          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               try {
                    car.car_create = dayjs(car.car_create).format("YYYY-MM-DD");
                    car.INVOICE_DATE = car.INVOICE_DATE ? dayjs(car.INVOICE_DATE).format("YYYY-MM-DD") : null;
                    car.DUE_DATE = car.DUE_DATE ? dayjs(car.DUE_DATE).format("YYYY-MM-DD") : null;
                    car.SCH_DATE = car.SCH_DATE ? dayjs(car.SCH_DATE).format("YYYY-MM-DD") : null;
                    car.FINAL_ASSIGN = car.FINAL_ASSIGN ? dayjs(car.FINAL_ASSIGN).format("YYYY-MM-DD") : null;
                    car.TMT_DEPARTURE_DATE = car.TMT_DEPARTURE_DATE ? dayjs(car.TMT_DEPARTURE_DATE).format("YYYY-MM-DD") : null;

                    await POST("/car/add", car);
                    swal({
                         text: "เพิ่มรถยนต์ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => {
                         window.location.assign("/admin/car/search/list/car");
                    });
                    event.preventDefault();
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          this.setState({
               validated: true,
          });
     };

     handleEdit = async () => {
          let { car, addEditPromotion, deletePromotion } = this.state;
          try {
               car.promotions = addEditPromotion;
               car.car_create = dayjs(car.car_create).format("YYYY-MM-DD");
               car.INVOICE_DATE = car.INVOICE_DATE ? dayjs(car.INVOICE_DATE).format("YYYY-MM-DD") : null;
               car.DUE_DATE = car.DUE_DATE ? dayjs(car.DUE_DATE).format("YYYY-MM-DD") : null;
               car.SCH_DATE = car.SCH_DATE ? dayjs(car.SCH_DATE).format("YYYY-MM-DD") : null;
               car.FINAL_ASSIGN = car.FINAL_ASSIGN ? dayjs(car.FINAL_ASSIGN).format("YYYY-MM-DD") : null;
               car.TMT_DEPARTURE_DATE = car.TMT_DEPARTURE_DATE ? dayjs(car.TMT_DEPARTURE_DATE).format("YYYY-MM-DD") : null;

               await POST("/car/edit", { ...car, deletePromotion });

               swal({
                    text: "แก้ไขรถยนต์ในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => window.location.reload());
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleDelete = async () => {
          let { car } = this.state;
          if (car.order_id === 0) {
               try {
                    // await POST("/car/edit", { car_id: this.state.car.car_id, delete: 1 });
                    await POST("/car/car_delete", { car_id: this.state.car.car_id });

                    swal({
                         text: "ลบรถยนต์ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                         // }).then(() => this.props.history.push("/admin/car"));
                    }).then(() => this.props.history.goBack());
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } else {
               swal({
                    text: "รถยนต์มีการจับคู่ ไม่สามารถลบได้",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleReset = () => {
          let { car } = this.state;

          Object.keys(car).forEach((el) => {
               car[el] = "";
               if (el === "promotions") {
                    car[el] = [];
               }
               if (el === "status") {
                    car[el] = "ใช้งาน";
               }
          });

          this.setState({ car });
     };

     handleClick = () => {
          let { show } = this.state;
          this.setState({
               show: !show,
          });
     };

     addItem = () => {
          let { promotions_data, pro_id, car, promotion_type, addEditPromotion } = this.state;
          let findAdd = promotions_data.find((e) => e.pro_id === Number(pro_id));
          if (pro_id) {
               car.promotions.push({
                    ...findAdd,
                    promotion_type,
               });
               addEditPromotion.push({ ...findAdd, promotion_type });
               this.setState({ car, addEditPromotion });
          } else {
          }
     };

     detailFormatter = (cell, row) => {
          return `${dayjs(row.start_date).format("DD/MM/")}${dayjs(row.start_date).year() + 543} - ${dayjs(row.end_date).format("DD/MM/")}${
               dayjs(row.end_date).year() + 543
          }`;
     };

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemove(row, rowIndex)} variant={"danger"}>
                    Remove
               </Button>
          );
     };
     numberFormatter = (cell, row, rowIndex) => {
          return <div style={{ textAlign: "center" }}>{rowIndex + 1}</div>;
     };

     // accPriceFormatter = (cell, row, rowIndex) => {
     //      if (cell) {
     //           return (cell * row.ac_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
     //      } else {
     //           return "0.00";
     //      }
     // };

     onRemove = (row, rowIndex) => {
          let { car, deletePromotion } = this.state;
          car.promotions.splice(rowIndex, 1);
          deletePromotion.push(row.carpo_id);
          this.setState({ car, deletePromotion });
     };
     handleChangeNumberCar = (value, rowIndex, name) => {
          let { car } = this.state;
          car.price_list[name] = value.floatValue;
     };
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Col style={{ textAlign: "center" }}>
                    <span>{`${rowIndex + 1}.`}</span>
               </Col>
          );
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={`${row.name}`}
                    value={row.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangeNumberCar(e, rowIndex, row.name)}
               />
          );
     };
     removeBtnAcc = (cell, row, rowIndex, formatExtraData) => {
          let { car } = this.state;
          return (
               <Button disabled={car.order_id} onClick={() => this.onRemoveAcc(row, rowIndex)} variant={"danger"}>
                    Remove
               </Button>
          );
     };
     onRemoveAcc = (row, index) => {
          let { car } = this.state;
          car.acc_installed.splice(index, 1);
          this.setState({ car });
     };
     render() {
          let {
               series,
               car,
               model,
               colors,
               validated,
               previous_path,
               title,
               serie,
               functions_yes,
               promotions_data,
               promotion_type,
               pro_id,
               dateValue,
          } = this.state;
          let segment = ["A-segment", "B-segment", "C-segment", "D-segment"];
          let typeCars = ["Pick-up", "MPV", "PPV", "SUV", "CUV", "HEV", "VAN", "Eco Car", "Compact Car", "Mid-size Car", "Full-size Car"];

          let typePromotion = ["ส่วนลดเงินดาวน์", "อุปกรณ์ตกแต่ง", "Leasing", "ประกัน"];

          const table = {
               columns: [
                    {
                         dataField: "promotion_type",
                         text: "ประเภทโปรโมชัน",
                         headerClasses: "header-custom __left __btn-s",
                    },
                    {
                         dataField: "promotion_name",
                         text: "โปรโมชัน",
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "date",
                         text: "ระยะเวลา",
                         headerClasses: "header-custom  __btn-s",
                         formatter: this.detailFormatter,
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeBtnFormatter,
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: car.promotions,
               keyField: "carpo_id",
          };

          let table_acc = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.numberFormatter,
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ",
                         headerClasses: "header-custom __l",
                    },
                    {
                         dataField: "supplier_name",
                         text: "บริษัท",
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeBtnAcc,
                         headerClasses: "header-custom __right __btn-s",
                    },
                    // {
                    //      dataField: "ac_amount",
                    //      text: "จำนวน",
                    //      headerClasses: "header-custom  __btn-s",
                    // },
                    // {
                    //      dataField: "items_price",
                    //      text: "ราคา (หน่วย)",
                    //      headerClasses: "header-custom  __btn-m",
                    //      formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"),
                    //      // formatter: this.accPriceFormatter,
                    // },
                    // {
                    //      dataField: "unit",
                    //      text: "หน่วย",
                    //      formatter: () => "บาท",
                    //      headerClasses: "header-custom __right __btn-m",
                    // },
               ],
               data: car.acc_installed ? car.acc_installed : [],
               keyField: "no",
          };
          let table_price = {
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "list",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "price",
                         text: "จำนวน (บาท)",
                         formatter: this.inputAmountFormatter,
                    },
                    {},
               ],
               data: [
                    {
                         list: "ต้นทุนรถยนต์",
                         price: car.price_list?.car_cost,
                         name: "car_cost",
                    },
                    {
                         list: "ต้นทุนเครื่องปรับอากาศ",
                         price: car.price_list?.air_conditioner_cost,
                         name: "air_conditioner_cost",
                    },
                    {
                         list: "ต้นทุนก่อนภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.cost_before_vat,
                         name: "cost_before_vat",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มของต้นทุน",
                         price: car.price_list?.cost_vat,
                         name: "cost_vat",
                    },
                    {
                         list: "ต้นทุนหลังภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.cost_after_vat,
                         name: "cost_after_vat",
                    },
                    {
                         list: "ต้นทุนอุปกรณ์",
                         price: car.price_list?.equipment_cost,
                         name: "equipment_cost",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มต้นทุนอุปกรณ์",
                         price: car.price_list?.equipment_cost_vat,
                         name: "equipment_cost_vat",
                    },
                    {
                         list: "ต้นทุนอุปกรณ์หลังภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.equipment_cost_after_vat,
                         name: "equipment_cost_after_vat",
                    },
                    {
                         list: "รวมต้นทุนทั้งหมด",
                         price: car.price_list?.total_cost,
                         name: "total_cost",
                    },
                    {
                         list: "ราคาขายรถยนต์",
                         price: car.price_list?.car_sale_price,
                         name: "car_sale_price",
                    },
                    {
                         list: "ราคาขายเครื่องปรับอากาศ",
                         price: car.price_list?.price_of_air_conditioner,
                         name: "price_of_air_conditioner",
                    },
                    {
                         list: "ราคาขายก่อนภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.car_sale_price_air_before_vat,
                         name: "car_sale_price_air_before_vat",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มของราคาขาย",
                         price: car.price_list?.price_of_air_conditioner_vat,
                         name: "price_of_air_conditioner_vat",
                    },
                    {
                         list: "ราคาขายหลังภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.car_sale_price_air_after_vat,
                         name: "car_sale_price_air_after_vat",
                    },
                    {
                         list: "ราคาขายอุปกรณ์",
                         price: car.price_list?.price_of_equipment,
                         name: "price_of_equipment",
                    },
                    {
                         list: "ภาษีมูลค่าราคาขายเพิ่มอุปกรณ์",
                         price: car.price_list?.price_of_equipment_vat,
                         name: "price_of_equipment_vat",
                    },
                    {
                         list: "ราคาขายอุปกรณ์หลังภาษีมูลค่าเพิ่ม",
                         price: car.price_list?.price_of_equipment_after_vat,
                         name: "price_of_equipment_after_vat",
                    },
                    {
                         list: "รวมราคาขายทั้งหมด",
                         price: car.price_list?.all_sales_prices,
                         name: "all_sales_prices",
                    },
                    {
                         list: "กำไรรถยนต์",
                         price: car.price_list?.car_profit,
                         name: "car_profit",
                    },
                    {
                         list: "กำไรเครื่องปรับอากาศ",
                         price: car.price_list?.air_conditioning_profit,
                         name: "air_conditioning_profit",
                    },
                    {
                         list: "กำไรอุปกรณ์",
                         price: car.price_list?.equipment_profit,
                         name: "equipment_profit",
                    },
                    {
                         list: "Margin มาตรฐาน",
                         price: car.price_list?.margin_cost,
                         name: "margin_cost",
                    },
                    // {
                    //      list: "ค่า พรบ.",
                    //      price: car.price_list?.act_premium,
                    //      name: "act_premium",
                    // },
                    // {
                    //      list: "ค่าจดทะเบียน",
                    //      price: car.price_list?.register_price,
                    //      name: "register_price",
                    // },
                    {
                         list: "คอมมิชชั่น Extra",
                         price: car.price_list?.commission_extra,
                         name: "commission_extra",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลธรรมดา",
                         price: car.price_list?.act_person_general_price,
                         name: "act_person_general_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลไม่เกิน 15 ที่นั่ง",
                         price: car.price_list?.act_person_notmore15_price,
                         name: "act_person_notmore15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลเกิน 15 ที่นั่ง",
                         price: car.price_list?.act_person_more15_price,
                         name: "act_person_more15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 7 ที่นั่ง",
                         price: car.price_list?.act_commerce_notmore7_price,
                         name: "act_commerce_notmore7_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 15 ที่นั่ง",
                         price: car.price_list?.act_commerce_notmore15_price,
                         name: "act_commerce_notmore15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 20 ที่นั่ง",
                         price: car.price_list?.act_commerce_notmore20_price,
                         name: "act_commerce_notmore20_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์เกิน 20 ที่นั่ง",
                         price: car.price_list?.act_commerce_more20_price,
                         name: "act_commerce_more20_price",
                    },
                    {
                         list: "ค่าจดทะเบียนบุคคลธรรมดา",
                         price: car.price_list?.register_normal,
                         name: "register_normal",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายตัวแทน",
                         price: car.price_list?.register_fun_normal_to_company,
                         name: "register_fun_normal_to_company",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายขนส่ง",
                         price: car.price_list?.register_fun_normal_to_delivery,
                         name: "register_fun_normal_to_delivery",
                    },
                    {
                         list: "ค่าจดทะเบียนนิติบุคคล",
                         price: car.price_list?.coporation,
                         name: "coporation",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายตัวแทน",
                         price: car.price_list?.register_fun_coporation_to_company,
                         name: "register_fun_coporation_to_company",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายขนส่ง",
                         price: car.price_list?.register_fun_coporation_to_delivery,
                         name: "register_fun_coporation_to_delivery",
                    },
                    {
                         list: "INNER",
                         price: car.price_list?.inner,
                         name: "inner",
                    },
                    {
                         list: "อื่นๆ",
                         price: car.price_list?.etc,
                         name: "etc",
                    },
               ],
               keyField: "no",
          };
          return (
               <div className="panel">
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} onClick={() => this.props.history.goBack()} /> : null}
                    <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                         <div className="content __input-panel">
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ข้อมูลรถ</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"เลขเครื่องยนต์"}
                                                            name={"car_engine"}
                                                            placeholder={"กรุณากรอก เลขเครื่องยนต์"}
                                                            require={true}
                                                            value={car?.car_engine}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"เลขตัวถัง"}
                                                            name={"car_tank"}
                                                            placeholder={"กรุณากรอก เลขตัวถัง"}
                                                            require={true}
                                                            value={car?.car_tank}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            title={"วันที่รถเข้า"}
                                                            name={"car_create"}
                                                            placeholder={"เลือกวันที่"}
                                                            value={dateValue.car_create || dayjs(new Date())}
                                                            require={true}
                                                            md={6}
                                                            handleSelectDate={this.handleSelectDate}
                                                            formatDate={"DD/MM/YYYY"}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"Segment"}
                                                            name={"car_segment"}
                                                            placeholdText={"กรุณาเลือก Segment"}
                                                            option={segment?.map((e) => ({
                                                                 value: e,
                                                                 name: e,
                                                            }))}
                                                            value={car?.car_segment}
                                                            // require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ประเภทรถ"}
                                                            name={"car_type"}
                                                            placeholdText={"เลือกประเภทรถ"}
                                                            option={typeCars?.map((e) => ({
                                                                 value: e,
                                                                 name: e,
                                                            }))}
                                                            value={car?.car_type}
                                                            // require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"สถานะ"}
                                                            name={"status"}
                                                            label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            value={car?.status}
                                                            // disabled={order?.insurance === 'No'}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="input-field">
                                   <div className="-header">
                                        <div>รายละเอียดเกี่ยวกับรถ</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"select"}
                                                            title={"Series"}
                                                            name={"series_id"}
                                                            placeholdText={"เลือก Series"}
                                                            option={series?.map((e) => ({
                                                                 value: e.series_id,
                                                                 name: e.series_name,
                                                            }))}
                                                            value={car?.series_id}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"Model"}
                                                            name={"model_id"}
                                                            placeholdText={"เลือก Model"}
                                                            option={model?.map((e) => ({
                                                                 value: e.model_id,
                                                                 name: e.model_name,
                                                            }))}
                                                            value={car?.model_id}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"Color"}
                                                            name={"color_id"}
                                                            placeholdText={"เลือก Color"}
                                                            option={colors?.map((e) => ({
                                                                 value: e.color_id,
                                                                 name: e.color_name,
                                                            }))}
                                                            value={car?.color_id ? car?.color_id : ""}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       {/* <Col md={3}> */}
                                                       <Field
                                                            type={"date"}
                                                            title={"ปีของรุ่นรถ"}
                                                            name={"car_year"}
                                                            placeholdText={"เลือกปี"}
                                                            formatDate={"YYYY"}
                                                            value={dateValue.car_year ? dateValue.car_year : ""}
                                                            // require={true}
                                                            picker={"year"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeYear}
                                                       />
                                                       {/* </Col> */}
                                                       {/* <Col md={12}></Col> */}
                                                       {/* <Field
                                                            type={"number"}
                                                            title={"ราคาทุน"}
                                                            name={"car_fun"}
                                                            placeholder={"0.00"}
                                                            require={true}
                                                            value={car?.car_fun ? car?.car_fun : ""}
                                                            md={3}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "car_fun")}
                                                       />
                                                       <Field
                                                            type={"number"}
                                                            title={"ราคาขาย"}
                                                            name={"car_price"}
                                                            placeholder={"0.00"}
                                                            require={true}
                                                            value={car?.car_price ? car?.car_price : ""}
                                                            md={3}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "car_price")}
                                                       />
                                                       <Field
                                                            type={"number"}
                                                            title={"ค่าคอมมิชชั่น Extra"}
                                                            name={"commission_extra"}
                                                            placeholder={"0.00"}
                                                            // require={true}
                                                            value={car.commission_extra ? car.commission_extra : ""}
                                                            md={6}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "commission_extra")}
                                                       /> */}
                                                       {detail_car.map((el) => (
                                                            <Field
                                                                 type={el.type}
                                                                 title={el.title}
                                                                 name={el.name}
                                                                 placeholder={el.placeholder}
                                                                 value={dateValue && dateValue[el.value] ? dateValue[el.value] : car[el.value]}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                                 handleSelectDate={this.handleSelectDate}
                                                                 formatDate={"DD/MM/YYYY"}
                                                            />
                                                       ))}
                                                       <Field
                                                            type={"text"}
                                                            title={"หมายเหตุ"}
                                                            name={"car_note"}
                                                            placeholder={"หมายเหตุ"}
                                                            value={car?.car_note}
                                                            md={12}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* /////////////////////////////////////////////////////////////// */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>กำหนดราคา</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <Table
                                                  type={"document"}
                                                  column={table_price.column}
                                                  data={table_price.data}
                                                  keyField={table_price.keyField}
                                             />
                                        </div>
                                   </div>
                              </div>
                              {/* /////////////////////////////////////////////////////////////// */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>รายการอุปกรณ์ที่ติดตั้งแล้ว</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content" id="table-content">
                                             <Table
                                                  type={"summary"}
                                                  column={table_acc.column}
                                                  data={table_acc.data}
                                                  keyField={table_acc.keyField}
                                                  // handleClick={this.addItem}
                                             />
                                        </div>
                                   </div>
                              </div>
                              {/* /////////////////////////////////////////////////////////////// */}

                              {/* <div className="input-field">
                                   <div className="-header">
                                        <div>Campaign/Promotion</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <p style={{ color: "#b3b3b3" }}>*สามารถเพิ่มได้ภายหลัง</p>
                                                  <Form.Row>
                                                       <Col xs={12} md={3}>
                                                            <Field
                                                                 type={"select"}
                                                                 title={"เลือกประเภทโปรโมชัน"}
                                                                 name={"promotionType"}
                                                                 placeholder={"เลือก ประเภทโปรโมชัน"}
                                                                 option={typePromotion?.map((e) => ({
                                                                      value: e,
                                                                      name: e,
                                                                 }))}
                                                                 value={promotion_type}
                                                                 // require={true}
                                                                 // md={3}
                                                                 handleChangeText={this.handleChangePromotionType}
                                                            />
                                                       </Col>

                                                       <Col xs={12} md={3}>
                                                            <Field
                                                                 type={"select"}
                                                                 title={"เลือกโปรโมชัน"}
                                                                 name={"promotions"}
                                                                 placeholder={"เลือก โปรโมชัน"}
                                                                 option={promotions_data?.map((e) => ({
                                                                      value: e.pro_id,
                                                                      name: e.promotion_name,
                                                                 }))}
                                                                 value={pro_id}
                                                                 // require={true}
                                                                 // md={3}
                                                                 handleChangeText={this.handleChangePromotion}
                                                            />
                                                       </Col>

                                                       <Col
                                                            xs={12}
                                                            md={3}
                                                            style={{
                                                                 justifyContent: "center",
                                                                 alignItems: "center",
                                                                 paddingTop: 24,
                                                            }}
                                                       >
                                                            <Row>
                                                                 <Col md={6}>
                                                                      <Button
                                                                           className="btn btn-success"
                                                                           style={{ height: 36, padding: 0 }}
                                                                           onClick={this.addItem}
                                                                      >
                                                                           + เพิ่ม
                                                                      </Button>
                                                                 </Col>
                                                                 <Col md={6}></Col>
                                                            </Row>
                                                       </Col>
                                                       <Col md={12}>
                                                            <Table
                                                                 type={"add-custom"}
                                                                 add={"text"}
                                                                 column={table.columns}
                                                                 data={table.data}
                                                                 keyField={table.keyField}
                                                                 // handleClick={this.addItem}
                                                            />
                                                       </Col>
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div> */}

                              {/* ////////////////// */}

                              <div className="input-field">
                                   {previous_path === "add" && (
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button type="submit">Submit</Button>
                                             </Col>
                                             <Col xs={6}>
                                                  <Button variant="danger" onClick={this.handleReset}>
                                                       Reset
                                                  </Button>
                                             </Col>
                                        </div>
                                   )}
                                   {previous_path === "edit" && (
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button onClick={this.handleEdit}>บันทึกการแก้ไข</Button>
                                             </Col>
                                             <Col xs={6}>
                                                  <Button variant="danger" onClick={this.handleDelete}>
                                                       ลบข้อมูล
                                                  </Button>
                                             </Col>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </Form>
               </div>
          );
     }
}
