import { Breadcrumbs, Table, Loader } from "components";
import { Button, Col } from "react-bootstrap";
import React, { Component } from "react";
import { GET, POST } from "api";
import swal from "sweetalert";

const title = [{ title: "รายการรถยนต์", onClick: () => window.location.replace("/admin/car") }, { title: "เพิ่มรถยนต์" }];

export default class CarUpload extends Component {
     constructor(props) {
          super(props);
          this.state = {
               carList: [],
               loading: false,
          };
     }

     componentDidMount = async () => {
          this.getCarUpload();
     };

     getCarUpload = async () => {
          this.setState({ loading: true });
          try {
               let res = await GET("/aos/get_car_master");
               // console.log(res);
               this.setState({ carList: res, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     /*-------------------Handle-------------------*/
     onDeleteCar = (row, rowIndex) => {
          // console.log(row);
          let { carList } = this.state;
          carList.splice(
               carList.findIndex((el) => el === row),
               1
          );
          // console.log(carList);
          this.setState(carList);
     };
     onSubmit = async () => {
          this.setState({ loading: true });
          let { carList, showModal } = this.state;
          try {
               let res = await POST("/aos/create_car_master", { arr: carList });
               if (res === "create_car_master success") {
                    swal({
                         text: "บันทึกสำเร็จ",
                         icon: "success",
                         button: "ตกลง",
                    }).then((value) => {
                         // window.location.assign("car/search/list/car");
                         window.location.reload();
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal({
                    text: "ไม่สามารถบันทึกได้",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               console.log("error", error);
          }
     };

     /*------------------Formatter--------------------*/
     carFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <span style={{ color: row.duplicate ? "green" : "red" }}>{cell}</span>;
     };
     carModelFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <span style={{ color: row.news ? "green" : "red" }}>{cell}</span>;
     };
     deleteFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <a style={{ color: "red", textAlign: "center" }} onClick={() => this.onDeleteCar(row, rowIndex)}>
                    ลบ
               </a>
          );
     };
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return `${rowIndex + 1}`;
     };
     render() {
          const { carList } = this.state;
          let table = {
               column: [
                    {
                         dataField: "edit",
                         text: " ",
                         formatter: this.deleteFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    // {
                    //      dataField: "no",
                    //      text: "ลำดับ",
                    //      headerClasses: "header-custom __left  __no",
                    //      formatter: this.noFormatter,
                    // },
                    // {
                    //      dataField: "series_code",
                    //      text: "รหัสรุ่น",
                    // },
                    {
                         dataField: "series_name",
                         text: "Series",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_code",
                         text: "รหัสโมเดล",
                         formatter: this.carModelFormatter,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         headerClasses: "header-custom __btn-ml",
                    },
                    // {
                    //      dataField: "car_cc",
                    //      text: "เครื่องยนต์",
                    // },
                    // {
                    //      dataField: "color_code",
                    //      text: "รหัสสี",
                    // },
                    // {
                    //      dataField: "color_tmt",
                    //      text: "ชื่อสีตาม TMT",
                    // },
                    {
                         dataField: "color_name",
                         text: "Color",
                         formatter: (e, el) => `${e} (${el.color_code})`,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         formatter: this.carFormatter,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_engine",
                         text: "เลขเครื่องยนต์",
                         formatter: this.carFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "car_note",
                         text: "หมายเหตุ",
                         headerClasses: "header-custom __btn-s",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (c) => (c ? c : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "พนักงานขาย",
                         headerClasses: "header-custom __right __btn-s",
                         formatter: (c) => (c ? c : "-"),
                    },
                    // {
                    //      dataField: "car_fun",
                    //      text: "ราคาต้นทุน",
                    // },
                    // {
                    //      dataField: "car_price",
                    //      text: "ราคาขาย",
                    // },
                    // {
                    //      dataField: "generate",
                    //      text: "ทำรายการ",
                    //      headerClasses: "header-custom __right  __no",
                    //      formatter: this.deleteFormatter,
                    // },
               ],
               data: carList,
               keyField: "no",
          };
          // console.log("carList", carList);
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={title}
                         active={title.length - 1}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "บันทึก",
                                   handleClick: this.onSubmit,
                              },
                         ]}
                    />
                    <div className="content __main-panel listcar">
                         {this.state.loading ? (
                              <Loader />
                         ) : (
                              <Table
                                   type={"summary"}
                                   column={table.column}
                                   data={table.data}
                                   keyField={table.keyField}
                                   amount={carList.length}
                                   used={carList.filter((el) => el.news).length}
                                   duplicate={carList.filter((el) => !el.duplicate).length}
                                   leasing="list-car"
                              />
                         )}
                    </div>
                    {/* <div className="input-field">
                         <div className="button-group __submit row">
                              <Col md={{ span: 5, offset: 11 }}>
                                   <Button onClick={this.onSubmit}>บันทึก</Button>
                              </Col>
                         </div>
                    </div> */}
               </div>
          );
     }
}
