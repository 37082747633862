import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Skeleton } from "../../../../../components/index";
import swal from "sweetalert";
import { GET, POST } from "api/index";
import dayjs from "dayjs";
import { getToken, getToken_permission_page } from "../../../../../static/function";

export default class Car extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               newSearch: [],
               search: {
                    acept_status: "ทั้งหมด",
                    branch_id: 0,
               },
               branchs: [],
               user: {},
               loading: false,
          };
     }
     componentDidMount = async () => {
          await this.setState({ user: getToken() });
          // console.log("users: ", this.state.user);
          this.getOrder();
          this.getBranchs();
     };

     getOrder = async () => {
          this.setState({ loading: true });
          let { user } = this.state;
          let fullname = `${user?.firstname} ${user?.lastname}`;
          try {
               let res = await POST("/order/limit", {
                    branch_id: Number(user.branch_id),
                    position_id: user.position_id,
                    user_id: user.user_id,
                    team_id: user.team_id,
               });
               this.setState({ order: res, loading: false });
               //========================================//
               // let res = await GET("/order/");
               // // console.log("res", res);
               // let order_search = res
               //      .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
               //      .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el))
               //      .filter((el) =>
               //           user.position_id === 18
               //                ? Number(el.saler_name) === user.user_id
               //                : user.position_id === 16
               //                ? Number(el.team_id) === user.team_id
               //                : el
               //      );
               // let order = order_search.filter((el) =>
               //      user.position_id === 17
               //           ? el.lvl_margin < 2 || el.lvl_margin === 4
               //           : user.position_id === 9
               //           ? el.lvl_margin === 2
               //           : user.position_id === 2
               //           ? el.lvl_margin === 3
               //           : user.position_id === 1
               //           ? el.lvl_margin === 4
               //           : el
               // );
               // // .filter((el) => el.acept_status === "ใบจองรออนุมัติ");
               // this.setState({ order, order_search });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     getBranchs = async () => {
          try {
               let branchs = await GET("/ps/get_branchs");
               branchs.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branchs: [...branchs] });
          } catch (error) {
               console.log(error);
          }
     };
     getApproveStatus = async () => {};
     getPreemtionStatus = async () => {};
     handleClick = () => {
          window.location.assign("/admin/order/car/detail/add");
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          // console.log("e.target.name", e.target.name, e.target.value);
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = date;
          this.setState({ ...search });
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = async () => {
          this.setState({ loading: true });
          let { order_search, search, newSearch, user } = this.state;
          try {
               let res = await POST("/order/order_search", {
                    branch_id: Number(user.branch_id),
                    position_id: user.position_id,
                    user_id: user.user_id,
                    team_id: user.team_id,
                    search,
               });
               this.setState({ order: res, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
          //====================================//
          // newSearch = order_search
          //      .filter(
          //           (el) =>
          //                (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
          //                (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
          //                (search.getcar_date ? dayjs(el.getcar_date).format("DD/MM/YYYY") === search.getcar_date : el) &&
          //                (search.acept_status
          //                     ? search.acept_status === "ทั้งหมด"
          //                          ? el
          //                          : String(el.acept_status) === String(search.acept_status)
          //                     : el)
          //      )
          //      .filter(
          //           (el) =>
          //                (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
          //                (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
          //                (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el) &&
          //                (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el) &&
          //                (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
          //      );
          // this.setState({ newSearch });
     };

     /* Formatter */
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.acept_status === "save") {
               return (
                    <a onClick={() => this.props.history.push({ pathname: `/admin/order/car/detail/add`, state: { order_id: row.order_id } })}>
                         ทำรายการ
                    </a>
               );
          } else {
               return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>ทำรายการ</a>;
          }
     };

     dateFormatter(cell, row, rowIndex, formatExtraData) {
          if (formatExtraData === "getcar_date") {
               return cell ? dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543) : "-";
          } else {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          }
     }
     statusFormatter = (cell, row, rowIndex, formatExtraData) => {
          let status;
          if (cell === "save" && (getToken().position_id === 11 || getToken().position_id === 20 || getToken().position_id === 25)) {
               return (
                    <label style={{ cursor: "pointer", textDecoration: "underline", color: "red" }} onClick={() => this.delOrderSave(row)}>
                         {cell}
                    </label>
               );
          } else {
               if (row.pay_choice === "เงินสด" && row.acept_status === "Leasing อนุมัติแล้ว") {
                    status = "รับเงินจองแล้ว";
               } else {
                    status = cell;
               }
               return status;
          }
     };
     pauseFormatter = (cell, row) => {
          let { search } = this.state;
          if (search.acept_status === "ปิดชั่วคราว") {
               return (
                    <label style={{ cursor: "pointer", textDecoration: "underline", color: "green" }} onClick={() => this.cancelPause(row)}>
                         เปิดอีกครั้ง
                    </label>
               );
          } else {
               return (
                    <label style={{ cursor: "pointer", textDecoration: "underline", color: "red" }} onClick={() => this.pauseOrder(row)}>
                         ปิดชั่วคราว
                    </label>
               );
          }
     };
     delOrderSave = async (row) => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST("/aos/del_order_save", { id: row.order_id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => this.getOrder());
                    }
               });
          } catch (error) {}
     };
     pauseOrder = async (row) => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการปิด Booking ชั่วคราว",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST("/aos/pause_order", { id: row.order_id });
                         swal({
                              text: "ปิด Booking ชั่วคราวเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => this.handleClickSearch());
                    }
               });
          } catch (error) {}
     };
     cancelPause = async (row) => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการเปิด Booking อีกครั้ง",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST("/aos/cancel_pause_order", { id: row.order_id });
                         swal({
                              text: "เปิด Booking เรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => this.handleClickSearch());
                    }
               });
          } catch (error) {}
     };
     orderFormatter = (cell, row) => {
          if (cell) {
               return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
          } else {
               return <span>-</span>;
          }
     };
     handleEnter = (e) => {
          if (e.key === "Enter") {
               this.handleClickSearch();
          }
     };
     render() {
          let { branchs, search, newSearch, order } = this.state;
          const table = {
               column: [
                    {
                         dataField: "detail",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "acept_status",
                         text: "สถานะใบจอง",
                         formatter: this.statusFormatter,
                         headerClasses: "header-custom __btn-sm",
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         headerClasses: "header-custom __btn-ss",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "branch_name",
                         text: "สาขา/โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "team_name",
                         text: "ทีม",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "getcar_date",
                         text: "วันที่ส่งมอบ",
                         formatter: this.dateFormatter,
                         formatExtraData: "getcar_date",
                         headerClasses: "header-custom __right",
                    },
                    // {
                    //      dataField: "status",
                    //      text: "การอนุมัติ",
                    // },
               ],
               column2: [
                    {
                         dataField: "detail",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "acept_status",
                         text: "สถานะใบจอง",
                         formatter: this.statusFormatter,
                         headerClasses: "header-custom __btn-sm",
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         headerClasses: "header-custom __btn-ss",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "branch_name",
                         text: "สาขา/โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "team_name",
                         text: "ทีม",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "getcar_date",
                         text: "วันที่ส่งมอบ",
                         formatter: this.dateFormatter,
                         formatExtraData: "getcar_date",
                         headerClasses: "header-custom",
                    },
                    {
                         dataField: "pause_order",
                         text: "",
                         formatter: this.pauseFormatter,
                         headerClasses: "header-custom __right",
                    },
                    // {
                    //      dataField: "status",
                    //      text: "การอนุมัติ",
                    // },
               ],
               // data: newSearch.length > 0 ? newSearch : order,
               data: order,
          };
          // เช็คสิทธิ์
          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path.includes("/admin/order/car"))[0].add || false;
          let role = getToken().position_id === 25 || getToken().position_id === 10 || getToken().position_id === 11 || getToken().position_id === 12;
          return (
               <div className="panel">
                    <Breadcrumbs
                         id={"add-order-car-btn"}
                         title={[{ title: "การจองรถยนต์" }]}
                         active={0}
                         button={
                              addBtn
                                   ? [
                                          {
                                               icon: "far fa-plus-square -icon",
                                               name: "เพิ่มใบจองรถ",
                                               handleClick: this.handleClick,
                                          },
                                     ]
                                   : []
                         }
                    />
                    <div className="content __main-panel carorder">
                         <Search
                              type={"car-order"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              handleEnter={this.handleEnter}
                              option={{
                                   branchs: branchs,
                                   value: {
                                        ...search,
                                   },
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={role ? table.column2 : table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={role ? table.column2 : table.column} data={table.data} keyField={"no"} />
                         )}
                    </div>
               </div>
          );
     }
}
